import React from "react";

import { SiteLink as Link, LinkList, SEO } from "@parataxic/shared-ui";

import { PopoverSiteLink } from "../components/Popover";
import { ResponsiveVideoPlayer } from "../components/VideoPlayer";

interface IProps {
  data: GatsbyTypes.VideoMediaListQuery;
  pageContext: { video: GatsbyTypes.VideoMediaItemFragment };
}

interface IVideoItemProps {
  video: GatsbyTypes.VideoMediaItemFragment;
}

const VideoItem: React.FC<IVideoItemProps> = ({ video }) => (
  <span style={{ width: "100%" }} role="article">
    <ResponsiveVideoPlayer controls video={video} />

    <div
      style={{
        color: "var(--color-gray-600)",
        fontSize: "0.8rem",
        padding: ".5rem 0",
      }}
    >
      <h3>{video.title}</h3>
      {video.description && <div>{video.description}</div>}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(330px)", // 100% on mobile
        }}
      >
        {(video.creators || video.mentionedPsychologists) && (
          <>
            {video.creators && (
              <div>
                <LinkList links={video.creators} Component={PopoverSiteLink} />
              </div>
            )}
            {video.mentionedPsychologists && (
              <div>
                <LinkList
                  links={video.mentionedPsychologists}
                  Component={PopoverSiteLink}
                />
              </div>
            )}
          </>
        )}
        {video.mentionedTheories && (
          <div>
            <LinkList
              links={video.mentionedTheories}
              Component={PopoverSiteLink}
            />
          </div>
        )}
        <div>
          <div
            style={{
              width: "100%",
              fontWeight: "bold",
              color: "var(--color-gray-600)",
              fontSize: ".9rem",
              paddingTop: "1rem",
            }}
          >
            <Link
              href={video.youtubeLink || "#"}
              rel="noopener noreferrer"
              target="_blank"
            >
              Watch on YouTube.com
            </Link>
          </div>
        </div>
      </div>
    </div>
  </span>
);

const View: React.FC<IProps> = ({ pageContext: { video } }) => {
  const seoProps: { description?: string } = {};
  if (video.description) {
    seoProps.description = video.description;
  }
  return (
    <>
      <SEO title={video.title} {...seoProps} />
      <div>
        <VideoItem video={video} />
      </div>
    </>
  );
};

export default View;
