import React from "react";
import { AspectRatio } from "react-aspect-ratio";
import ReactPlayer, { ReactPlayerProps } from "react-player/lazy";

import "react-aspect-ratio/aspect-ratio.css";

interface IProps extends ReactPlayerProps {
  video: GatsbyTypes.MdxVideo;
}

export const VideoPlayer: React.FC<IProps> = ({ video, ...props }) => {
  if (!video.youtubeLink) {
    return null;
  }
  return <ReactPlayer url={video.youtubeLink} {...props} />;
};

export const ResponsiveVideoPlayer: React.FC<IProps> = ({
  video,
  ...props
}) => {
  return (
    <AspectRatio ratio="16/9">
      <VideoPlayer
        video={video}
        width="100%"
        height="100%"
        className="react-player"
        {...props}
      />
    </AspectRatio>
  );
};
